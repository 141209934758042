<div class="outer-content p-2 pt-5 px-md-5 pb-0" style="background-color: #d7d8d969;">
  <div class="inner-content p-2 pt-5 pt-md-4 px-md-5 pb-0">
    <!-- Navbar STart -->
    <header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()">
      <div class="container-fluid">
        <!-- Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div>
          <a class="logo" [ngxScrollTo]="'#home'">
            <!-- <img src="assets/images/logo-dark.png" height="24" alt=""> -->
            <h3 class="mb-0 ps-xl-4"> Criozen</h3>
          </a>
        </div>
       
     

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul class="navigation-menu">
            <li class="has-submenu" [ngClass]="{'active':currentSection === 'home'}">
              <a href="javascript: void(0);" [ngxScrollTo]="'#home'">Home</a>
            </li>
            <li class="has-submenu" [ngClass]="{'active':currentSection === 'aboutus'}">
              <a href="javascript: void(0);" [ngxScrollTo]="'#aboutus'">About Us</a>
            </li>
            <li class="has-submenu" [ngClass]="{'active':currentSection === 'whatWeDo'}">
              <a href="javascript: void(0);" [ngxScrollTo]="'#whatWeDo'">What We Do</a>
            </li>
            <li class="has-submenu" [ngClass]="{'active':currentSection === 'services'}">
              <a href="javascript: void(0);" [ngxScrollTo]="'#services'">Services</a>
            </li>

            <li class="has-submenu" routerLink="/page-comingsoon">
              <a href="javascript: void(0);" [ngxScrollTo]="'#contact'">Our Product</a>
            </li>
            <li class="my-auto">
               <div class="buy-button" [ngClass]="{'active':currentSection === 'contact'}">
                  <a [ngxScrollTo]="'#contact'" class="btn btn-primary d-none d-md-flex">Contact Us</a>
                </div>
            </li>
          </ul>
          <!--end navigation menu-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
    
    <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)" style="border-radius: 2rem; border: 5px solid #fff;">
    <!-- <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)" style="background: url('https://products.ls.graphics/mesh-gradients/images/49.-Soft-Peach_1.jpg')"> -->
      <!-- Hero Start -->

      <div class="animated-background-wrap">
        <div class="shape shape1"></div>
        <div class="shape shape2"></div>
        <div class="shape shape3"></div>
        <div class="shape shape4"></div>
      </div>
      <section class="bg-marketing d-table w-100" style="background: url('assets/images/marketing/marketing-shape.png'); backdrop-filter: blur(6px); border-radius: 2rem; background-repeat: no-repeat;" id="home">
      <!-- <section class="bg-marketing d-table w-100" style="background: url('assets/images/marketing/marketing-shape.png'), linear-gradient(152.97deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 100%); backdrop-filter: blur(6px);" id="home"> -->
      <!-- <section class="bg-marketing d-table w-100" style="background: url('https://www.getpeople.app/images/linear-bg.webp')"  id="home"> -->
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-lg-10 col-md-10 text-center">
            <img  data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease" src="assets/images/marketing/marketing.png" class="img-fluid" style="max-height: 400px" alt="">

            <div class="title-heading mt-0 mt-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h1 class="heading mb-3">
                <ngx-typed-js [strings]="['Unique', 'Secure', 'Innovative']" [shuffle]="true"
                  [typeSpeed]="80" [loop]="true">
                Looking for <span class="element text-primary typing"></span>digital Products? <br> We offer you breakthrough solutions.
                </ngx-typed-js>
              </h1>
              <p class="para-desc text-muted mx-auto">We believe in delivering user-centric products and services for our clients. For that, we’ve got the latest in technology and the expertise to make that happen.</p>
              <div class="mt-4 pt-2">
                <!-- <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Get Started</a> -->
                <a [ngxScrollTo]="'#contact'" class="btn btn-outline-primary mt-2"><i class="mdi mdi-mail"></i> Contact
                  us</a>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      </section>
      <!--end section-->
      <!-- Hero End -->
    <!-- START: ABOUT US -->
      <section class="section  bg-light" id="aboutus">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-12 order-1 order-lg-2">
                    <div class="section-title text-center text-lg-start mb-4 mb-lg-0 pb-2 pb-lg-0">
                      <h6 class="text-primary">About Criozen</h6>
                      <h4 class="title mb-4">The story of Criozen</h4>
                      <p class="text-muted">Criozen was founded in 2021 by a group of passionate graduates out of the desire to solve problems with technology-based tailor-made solutions. As a team, we believe, think, create and Innovate. At Criozen, we are dedicated to pioneering innovative and industry-leading solutions with its team of highly-skilled professionals. In addition to delivering cutting-edge solutions to global customers, we are pioneering the use of Blockchain technology.

                      </p>
                      <!-- <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> -->
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-6 col-12 order-2 order-lg-1">
                  <div class="position-relative me-lg-5"><img src="assets/images/company/about2.png" alt="" class="img-fluid mx-auto"><div class="play-icon"><a href="javascript:void(0);" class="play-btn video-play-icon"><i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i></a><!--container--></div></div>
                    <!--end Row-->

                    <!-- <div class="mt-4 d-lg-none d-block text-center">
                        <a href="javascript:void(0)" class="btn btn-primary">See more</a>
                    </div> -->
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!-- <div class="container mt-5">
          <app-clients-logo></app-clients-logo>
        </div> -->
        <!--end container-->
    </section>
      <!-- END: ABOUT US -->

      <!-- START: WHAT WE DO -->
      <section class="section" id="whatWeDo">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">What we do ?</h4>
                <p class="text-muted para-desc mb-0 mx-auto">We specialize in building enterprise software solutions that are tailored to meet the needs of your business.</p>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
      
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                  <div class="icons text-primary text-center mx-auto">
                      <i class="uil uil-browser d-block rounded h3 mb-0"></i>
                  </div>
                  <div class="card-body p-0 mt-4">
                      <a href="javascript:void(0)" class="title h5 text-dark">Web Development</a>
                      <p class="text-muted mt-2">We always build scalable, secure, and robust web applications.
                      </p>
                      <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                              class="uil uil-angle-right-b"></i></a> -->
                      <i class="uil uil-browser text-primary full-img"></i>
                  </div>
              </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                    <i class="uil uil-mobile-android d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                    <a href="javascript:void(0)" class="title h5 text-dark">Mobile Development</a>
                    <p class="text-muted mt-2">We develop high-performance, engaging mobile apps that grow with your business.
                    </p>
                    <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                            class="uil uil-angle-right-b"></i></a> -->
                    <i class="uil uil-mobile-android text-primary full-img"></i>
                </div>
            </div>
        </div>
        <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Blockchain Development</a>
                        <p class="text-muted mt-2">
                          We specialize in building EVM smart contracts for a variety of blockchains.
                        </p>
                        <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a> -->
                        <i class="uil uil-layer-group text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

          

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-cloud-database-tree d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">DevOps Solution</a>
                        <p class="text-muted mt-2">Maintaining a scalable and secure architecture based on AWS solutions.
                        </p>
                        <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a> -->
                        <i class="uil uil-cloud-database-tree text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                  <div class="icons text-primary text-center mx-auto">
                      <i class="uil uil-analytics d-block rounded h3 mb-0"></i>
                  </div>
                  <div class="card-body p-0 mt-4">
                      <a href="javascript:void(0)" class="title h5 text-dark">QA Testing</a>
                      <p class="text-muted mt-2">Our QA and testing services help businesses build secure and bug-free apps.
                      </p>
                      <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                              class="uil uil-angle-right-b"></i></a> -->
                      <i class="uil uil-analytics text-primary full-img"></i>
                  </div>
              </div>
          </div>
          <!--end col-->
        </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!-- END: WHAT WE DO -->
      <section class="section bg-light">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">Our Blockchain Solutions</h4>
                <p class="text-muted para-desc mb-0 mx-auto">Our blockchain platform can help transform the business lifecycle for digital ecosystems, while promoting trust, transparency and efficiency.</p>
              </div>
            </div>
            <!--end col-->
          </div>
          <div class="row">
              <div class="d-flex flex-column flex-md-row">
                  <ul ngbNav #nav="ngbNav"
                      class="nav nav-pills flex-column mb-0 sticky-bar col-md-3" style="background-color: transparent !important;"
                      orientation="vertical">
                      <li ngbNavItem="top" class="nav-item rounded" style="border: 1px solid #dee2e6">
                          <a ngbNavLink class="nav-link rounded">
                              <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                  <h6 class="mb-0"> <i class="uil uil-postcard me-2 h5"></i>Token</h6>
                                  <i class="uil uil-angle-right-b"></i>
                              </div>
                          </a>
                          <ng-template ngbNavContent>
                              <div class="tab-pane fade show active rounded" id="developing"
                                  role="tabpanel" aria-labelledby="proposal">
                                  <div class="row me-lg-5">
                                    <div class="col-md-6 col-12">
                                        <div class="row">
                                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-rocket d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Token Generator</a>
                                                        <p class="text-muted mt-2 mb-0">Create your own ERC20 or BEP20 Token in less than a minute with the most used Smart Contract Generator.</p>
                                                        <i class="uil uil-rocket text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-airplay d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Token Launchpad</a>
                                                        <p class="text-muted mt-2 mb-0"> Allows users to have first-look access to promising new crypto tokens from various projects.</p>
                                                        <i class="uil uil-airplay text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end Row-->
                                    </div>
                                    <!--end col-->
                
                                    <div class="col-md-6 col-12">
                                        <div class="row pt-lg-4 mt-lg-4">
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-envelope d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Airdrop Tokens</a>
                                                        <p class="text-muted mt-2 mb-0">An airdrop is an unsolicited distribution of a cryptocurrency token or coin, to numerous wallet addresses.</p>
                                                        <i class="uil uil-envelope text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-bell d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Token Migrator</a>
                                                        <p class="text-muted mt-2 mb-0">Moving tokens from one blockchain to another as a result of a change in the blockchain.</p>
                                                        <i class="uil uil-bell text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end Row-->
                                    </div>
                                    <!--end col-->
                                </div>
                              </div>
                              <!--end teb pane-->
                          </ng-template>
                      </li>
                      <li ngbNavItem="middle" class="nav-item rounded mt-2" style="border: 1px solid #dee2e6">
                          <a ngbNavLink class="nav-link rounded">
                              <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                  <h6 class="mb-0"> <i class="uil uil-notes me-2 h5"></i>NFT</h6>
                                  <i class="uil uil-angle-right-b"></i>
                              </div>
                          </a>
                          <ng-template ngbNavContent>
                              <div class="tab-pane fade show active" id="developing"
                                  role="tabpanel" aria-labelledby="proposal">
                                  <div class="row me-lg-5">
                                    <div class="col-md-6 col-12">
                                        <div class="row">
                                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-rocket d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">NFT Generator <br>
                                                          </a>
                                                        <p class="text-muted mt-2 mb-0">NFTs are non-fungible tokens that assist you to trade digital artwork on the blockchain platform. NFT is a new way of representing digital assets, which records ownership via blockchain.</p>
                                                        <i class="uil uil-rocket text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-airplay d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">NFT Launchpad</a>
                                                        <p class="text-muted mt-2 mb-0">With the help of launchpads, creators can raise funds to build, launch projects, or do the marketing of their projects.</p>
                                                        <i class="uil uil-airplay text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end Row-->
                                    </div>
                                    <!--end col-->
                
                                    <div class="col-md-6 col-12">
                                        <div class="row pt-lg-4 mt-lg-4">
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-envelope d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">NFT Locker</a>
                                                        <p class="text-muted mt-2 mb-0">NFTs can be used as a locker for crypto assets. You can select asset to put into a locker NFT and you can choose a release schedule for the token.</p>
                                                        <i class="uil uil-envelope text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-bell d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Airdrop NFTs</a>
                                                        <p class="text-muted mt-2 mb-0">An NFT airdrop is a distribution of NFTs or digital assets that are sent to a web3 wallet address for free as a promotion, or as added value for purchasing another digital asset. </p>
                                                        <i class="uil uil-bell text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end Row-->
                                    </div>
                                    <!--end col-->
                                </div>
                              </div>
                              <!--end teb pane-->
                          </ng-template>
                      </li>
                      <!-- <li ngbNavItem="bottom" class="nav-item rounded mt-2" style="border: 1px solid #dee2e6">
                          <a ngbNavLink class="nav-link rounded">
                              <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                  <h6 class="mb-0"> <i class="uil uil-folder-check me-2 h5"></i>Earn</h6>
                                  <i class="uil uil-angle-right-b"></i>
                              </div>
                          </a>
                          <ng-template ngbNavContent>
                              <div class="tab-pane fade show active" id="developing"
                                  role="tabpanel" aria-labelledby="proposal">
                                  <div class="row me-lg-5">
                                    <div class="col-md-6 col-12">
                                        <div class="row">
                                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-rocket d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Trade</a>
                                                        <p class="text-muted mt-2 mb-0">Whether it's a crypto-trading platform or a smart contract tool, blockchain continues to make a case for its enormous future potential in financial trading.</p>
                                                        <i class="uil uil-rocket text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                          
                
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-airplay d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Farming</a>
                                                        <p class="text-muted mt-2 mb-0">Stake LP tokens to earn.</p>
                                                        <i class="uil uil-airplay text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                   
                                    </div>
                                  
                
                                    <div class="col-md-6 col-12">
                                        <div class="row pt-lg-4 mt-lg-4">
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-envelope d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Staking</a>
                                                        <p class="text-muted mt-2 mb-0">Staking is a way of earning rewards for holding certain cryptocurrencies or additional tokens and getting some voting rights.</p>
                                                        <i class="uil uil-envelope text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                          
                
                                            <div class="col-12 mt-4 pt-2">
                                                <div
                                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                                    <div class="icons text-primary text-center mx-auto">
                                                        <i class="uil uil-bell d-block rounded-pill h3 mb-0"></i>
                                                    </div>
                                                    <div class="card-body p-0 mt-4">
                                                        <a href="javascript:void(0)" class="title h5 text-dark">Vesting</a>
                                                        <p class="text-muted mt-2 mb-0">The act of restricting the sale of a token for a predefined period of time.</p>
                                                        <i class="uil uil-bell text-primary full-img"></i>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        
                                    </div>
                                  
                                </div>
                              </div>
                            
                          </ng-template>
                      </li> -->
                  </ul>

                  <div [ngbNavOutlet]="nav" class="ms-lg-4 col-md-9"></div>
              </div>
          </div>
        </div>
      </section>

      <!-- BEGIN: BLOCKCHAIN SERVICES -->
      <section class="section" id="services">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">Our Blockchain Developemnt</h4>
                <!-- <p class="text-muted para-desc mb-0 mx-auto">Our services help create decentralized apps that provide increased traceability and security of data and transactions.
                </p> -->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
      
          <div class="row">  
            <div class="col-lg-6 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-airplay"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>dApps Development</h5>
                  <p class="para text-muted mb-0">From planning to design and development, our blockchain developers build enterprise-grade decentralized applications to help clients accelerate time to market and maximize ROI.</p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-airplay"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-6 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-rocket"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Smart Contract Development</h5>
                  <p class="para text-muted mb-0">Our smart contract development includes developing and deploying self-executing contracts for public and private blockchain networks. We have expertise in building smart contracts for crowdfunding.</p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-rocket"></i>
                </span>
              </div>
            </div>
            <!--end col-->

      
            <div class="col-lg-6 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-users-alt"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Tokenization</h5>
                  <p class="para text-muted mb-0">Our blockchain development team can help you tokenize any asset you want, ensuring trust, transparency and efficiency. Tokenization eliminates volatility and brings more liquidity.</p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-users-alt"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-6 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-file-alt"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>DeFi Development</h5>
                  <p class="para text-muted mb-0">Delivering groundbreaking Fintech applications by ideating beyond the limits of conventional thought and implementing decentralized finance solutions to tackle modern-day problems. </p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-file-alt"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-6 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-search"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Blockchain Wallet Development</h5>
                  <p class="para text-muted mb-0">Our blockchain developers build robust and feature-rich web and mobile wallet applications that support the exchange of various digital assets and currencies.</p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-search"></i>
                </span>
              </div>
            </div>
            <!--end col-->
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!-- END: BLOCKCHAIN SERVICES -->

      <section class="section bg-light">
      <div class="container my-4">
        <div class="row">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Our Blockchain Services</h4>
              <p class="text-muted para-desc mb-0 mx-auto">Our services help create decentralized apps that provide increased traceability and security of data and transactions.
              </p>
            </div>
          </div>
        </div>
        <div class="card-columns">
          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-crosshairs"></i>
            </span>
              <div class="card-body">
                <h5>Blockchain Consulting</h5>
                <p class="para text-muted mb-0">Our approach to blockchain consulting begins with what, why, and how blockchain technology can benefit your enterprise solution. </p>
              
              </div>
            <span class="big-icon text-center">
              <i class="uil uil-crosshairs"></i>
            </span>
          </div>


          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>NFT Marketplace</h5>
              <p class="para text-muted mb-0">We help businesses build their protocol-specific decentralized NFT marketplaces to organize NFT trading, biding, and selling digital assets.</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Farming</h5>
              <p class="para text-muted mb-0">A tool for locking token to get a reward. Farms require you to stake two tokens to get LP Tokens, which you then stake in the Farm to earn rewards.</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Vesting</h5>
              <p class="para text-muted mb-0">Vesting is a process of smart contracts usually lock a certain amount of funds until contract conditions are met. </p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Staking</h5>
              <p class="para text-muted mb-0">Staking is a way of earning rewards for holding certain cryptocurrencies.</p>           
              <!-- <p class="para text-muted mb-0">Staking offers crypto holders a way of putting their digital assets to work and earning passive income without needing to sell them.</p>            -->
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Bridge</h5>
              <p class="para text-muted mb-0"> A bridge is a connection that allows to transfer tokens and/or arbitrary data from one chain to another.</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Launchpad</h5>
              <p class="para text-muted mb-0">Raise capital to kick start your blockchain project
                Decentalised presale service.</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>
          
          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Crowdsale</h5>
              <p class="para text-muted mb-0">A crowdsale is a public offering to invest in a new cryptocurrency or other digital asset, such as a non-fungible token (NFT)</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Security Audit</h5>
              <p class="para text-muted mb-0">Security audits consist of a peer review of a smart contract or blockchain code to identify potential bugs or flaws.</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>
          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Swap</h5>
              <p class="para text-muted mb-0">Swap allows users to easily exchange one cryptocurrency for another without leaving their Blockchain Wallet. </p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>
          
          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-chart-line"></i>
            </span>

            <div class="card-body">
              <h5>Liquidity Pool</h5>
              <p class="para text-muted mb-0">A collection of tokens or digital assets locked in a smart contract that provide essential liquidity to decentralized exchanges.</p>           
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-chart-line"></i>
            </span>
          </div>

          <div class="card features fea-primary rounded p-2 p-lg-4 bg-white text-center position-relative overflow-hidden border-0 mt-4">
            <span class="h1 icon2 text-primary">
              <i class="uil uil-clock"></i>
            </span>
            
              <div class="card-body">
                <h5>Decentralized Exchange</h5>
                <p class="para text-muted mb-0">Our blockchain development team creates hack-proof and robust decentralized exchange platforms on Android and iOS to enable the real-time exchange of digital currencies securely and efficiently.</p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-clock"></i>
            </span>
          </div>
        </div>
      </div>
      </section>


      <!-- BEGIN: Platform Work on -->
      <section class="section">
        <div class="container">
          <div class="row justify-content-center">
              <div class="col-12 text-center">
                  <div class="section-title mb-4 pb-2">
                      <h4 class="title mb-4">Why Criozen</h4>
                      <p class="text-muted para-desc mx-auto mb-0">Whether you are an early stage startup, or a multi-national corporation looking for growth, scaling, or transformation, we deliver Enterprise Blockchain Services and flawless solutions to your business with comprehensive competence.</p>
                  </div>
              </div>
              <!--end col-->
          </div>
          <!--end row-->
      
          <div class="row">
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Early Technology Adopters</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Project Confidentiality</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">On-time Project Delivery</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="bold" class="fea icon-ex-md text-primary"></i-feather>>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Excellent Customization</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Round The Clock Support</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Dedicated Team & Support</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">100% Transparency</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="git-merge" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Quick Upgrade Services</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
              <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                      <div class="icon text-center rounded-circle me-3">
                          <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
                      </div>
                      <div class="flex-1">
                          <h4 class="title mb-0">Deep Industry Knowledge</h4>
                      </div>
                  </div>
              </div>
              <!--end col-->
      
          </div>
          <!--end row-->
        </div>
      </section>  
      <!-- END: Platform Work on -->

      <section class="section bg-light">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">Benefits of Blockchain Development</h4>
                <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                    class="text-primary fw-bold">Blockchain Development Solutions</span> </p> -->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
      
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-chart-line"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Transaprency</h5>
                  <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-chart-line"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-crosshairs"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Efficiency</h5>
                  <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-crosshairs"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-airplay"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Security</h5>
                  <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-airplay"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-rocket"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Speed</h5>
                  <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-rocket"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-clock"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Traceability</h5>
                  <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-clock"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-users-alt"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Immutability</h5>
                  <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-users-alt"></i>
                </span>
              </div>
            </div>
            <!--end col-->
      
            <!-- <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-file-alt"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Certified Company</h5>
                  <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-file-alt"></i>
                </span>
              </div>
            </div> -->
            <!--end col-->
      
            <!-- <div class="col-lg-3 col-md-4 mt-4 pt-2">
              <div
                class="card features fea-primary rounded p-4 text-center position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                  <i class="uil uil-search"></i>
                </span>
                <div class="card-body p-0 content">
                  <h5>Data Analytics</h5>
                  <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
                </div>
                <span class="big-icon text-center">
                  <i class="uil uil-search"></i>
                </span>
              </div>
            </div> -->
            <!--end col-->
      
            <!-- <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
              <a href="javascript:void(0)" class="btn btn-primary">See more
                <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
              </a>
            </div> -->
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>

      <!-- Contact Start -->
      <section class="section" id="contact">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Talk to Our Experts</h4>
                <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Having an idea to build a blockchain product? Or are you looking for a dynamic blockchain integration service? Schedule a direct appointment with our blockchain experts.
                </p>
                <br>
                <h3>Reach us at -  <a href="mailto:support@criozen.com"> <strong>support@criozen.com</strong></a></h3>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

        
          <!--end row-->
        </div>
        <!--end contact-->
      </section>
      <!--end section-->
      <!-- Contact End -->

      <!-- Back to top -->
    <div class="d-flex">
      <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
        <i-feather name="arrow-up" class="icons"></i-feather>
      </a>
      <a href="mailto:support@criozen.com" class="btn btn-primary btn-icon mail__icon--animate mail__icon">
        <i-feather name="mail" class="icons"></i-feather>
      </a>
    </div>
      <!-- Back to top -->
      <app-footer></app-footer>
    </div>
  </div>
</div>
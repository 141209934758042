<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <h3 class="font-bold text-white">Criozen</h3>
                            <!-- <img src="assets/images/logo-dark.png" height="24" alt=""> -->
                        </a>
                        <p class="mt-4 text-muted">We equip Business Software's and Blockchain Development with the most reliable and advanced solutions</p>
                        <!-- <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul> -->
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Company</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-aboutus" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    About us</a></li>
                            <li><a routerLink="/page-services" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Services</a></li>
                            <li><a routerLink="/page-team" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    What We Do</a></li>
                            <li><a routerLink="/page-comingsoon" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Our Product</a></li>
                            <!-- <li><a routerLink="/portfolio-modern-three" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Project</a></li> -->
                            <!-- <li><a routerLink="/page-jobs" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Careers</a></li>
                            <li><a routerLink="/page-blog-grid" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Blog</a></li>
                            <li><a routerLink="/auth-cover-login" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Login</a></li> -->
                        </ul>
                    </div>
                    <!--end col-->

                    <!-- <div class="col-lg-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-terms" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Terms of Services</a></li>
                            <li><a routerLink="/page-privacy" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy</a></li>
                            <li><a routerLink="/documentation" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Documentation</a></li>
                            <li><a routerLink="/changelog" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Changelog</a></li>
                            <li><a routerLink="/components" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Components</a></li>
                        </ul>
                    </div> -->
                    <!--end col-->

                    <div class="col-lg-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Newsletter</h5>
                        <p class="mt-4 text-muted">Sign up and receive the latest tips via email.</p>

                        <form>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="foot-subscribe foot-white mb-3">
                                        <label class="form-label">Write your email <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" name="email" id="emailsubscribe"
                                                class="form-control bg-light border ps-5 rounded"
                                                placeholder="Your email : " required>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="d-grid">
                                        <input type="submit" id="submitsubscribe" name="send"
                                            class="btn btn-soft-primary" value="Subscribe">
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-12">
                        <a href="#" class="logo-footer">
                            <h3 class="font-bold text-white">Criozen</h3>
                            <!-- <img src="assets/images/logo-light.png" height="24" alt=""> -->
                        </a>
                        <!-- <p class="mt-4">We equip Business Software's and Blockchain Development with the most reliable and advanced solutions.</p> -->
                        <!-- <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul> -->
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-5 col-12">
                        <!-- <h5 class="text-light footer-head">Company</h5> -->
                        <ul class="list-unstyled footer-list d-md-flex gap-4">
                            <li [ngClass]="{'active':currentSection === 'home'}">
                                <a [ngxScrollTo]="'#home'" class="text-foot cursor-pointer">
                                    <!-- <i class="uil uil-angle-right-b me-1"></i> -->
                                About us</a>
                            </li>
                            <li [ngClass]="{'active':currentSection === 'what-we-do'}">
                                <a  [ngxScrollTo]="'#what-we-do'" class="text-foot">
                                    <!-- <i class="uil uil-angle-right-b me-1"></i> -->
                                    What We Do</a></li>
                            <li [ngClass]="{'active':currentSection === 'services'}">
                                <a [ngxScrollTo]="'#services'" class="text-foot">
                                    <!-- <i class="uil uil-angle-right-b me-1"></i> -->
                                    Services</a></li>
                            
                            <li><a routerLink="/page-comingsoon" class="text-foot">
                                <!-- <i class="uil uil-angle-right-b me-1"></i> -->
                                    Our Product</a></li>
                            <!-- <li><a routerLink="/portfolio-modern-three" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i> Project</a></li> -->
                        </ul>
                    </div>
                    <!--end col-->

                    <!-- <div class="col-lg-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-terms" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Terms of Services</a></li>
                            <li><a routerLink="/page-privacy" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy</a></li>
                            <li><a routerLink="/documentation" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Documentation</a></li>
                            <li><a routerLink="/changelog" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Changelog</a></li>
                            <li><a routerLink="/components" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Components</a></li>
                        </ul>
                    </div> -->
                    <!--end col-->

                    <div class="col-lg-3 col-12 mt-4 mt-lg-0">
                        <p class="mb-0">© 2023 Criozen.
                        </p>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->

<!--end footer-->

<!-- <app-switcher></app-switcher> -->

<!-- <router-outlet></router-outlet> -->

<!-- <app-page-maintenance></app-page-maintenance> -->

<modal-outlet></modal-outlet>

<app-carousel></app-carousel>  

<app-index-onepage></app-index-onepage>